import draggable from 'vuedraggable'
import _cloneDeep from 'lodash/cloneDeep'
export default {
    inheritAttrs: false,
    name: 'VueTable',
    components: {
        draggable
    },
    props: {
        title: { type: String, default: '' },
        items: { type: Array, default: () => [] },
        headers: { type: Array, default: () => [] },
        showRowActions: { type: Boolean, default: false },
        showTopHeader: { type: Boolean, default: true },
        sortingEnable: { type: Boolean, default: false },
        hasSearch: { type: Boolean, default: false },
        hasFooter: { type: Boolean, default: true },
        hasHeader: { type: Boolean, default: true },
        showSelect: { type: Boolean, default: true },
        singleSelect: { type: Boolean, default: false },
        hasExpand: { type: Boolean, default: false },
        icon: { type: String },
        emptyText: { type: String, default: 'No data' },
        noMoreData: { type: Boolean, default: false },
        noRowEdit: { type: Boolean, default: false },
        noRowDelete: { type: Boolean, default: false },
        noRowView: { type: Boolean, default: false },
        loading: { type: Boolean, default: true },
        draggable: { type: Boolean, default: false },
        minHeight: { type: String, default: null },
        outlined: { type: Boolean, default: true }
    },
    data: () => ({
        expanded: [],
        itemsPerPage: 1000,
        dialog: false,
        editedIndex: -1,
        editedItem: {},
        defaultItem: {},
        sortby: false,
        computedHeaders: [],
        search: '',
        page: 1,
        can_bulk_delete: false,
        btnloading: false,
        selected: []
    }),

    created() {
        this.$event.$on('btnloading_off', () => (this.btnloading = false))
        this.$event.$on('clear_selected', () => (this.selected = []))
        this.$event.$on('loading_off', () => (this.loading = false))
    },
    watch: {
        selected(val) {
            this.$emit('selected', val)
        }
    },
    computed: {
        adjustedHeaders() {
            let header = _cloneDeep(this.headers)
            if (this.hasExpand) {
                header.push({
                    text: '',
                    value: 'table-expand',
                    sortable: false,
                    width: 40
                })
            }
            return header
        }
    },
    methods: {
        handleSelectAllToggle(event) {
            this.can_bulk_delete = event.value
        },

        handleLoadMore() {
            this.btnloading = true
            this.$emit('load-more')
        },

        handleSorting(items) {
            this.selected = []
            this.$emit('sorting', items)
        },
        toggleExpand(value) {
            let index = this.expanded.indexOf(value)
            if (~index) {
                this.expanded.splice(index, 1)
            } else {
                this.expanded.push(value)
            }
        },
        is_expanded(value) {
            let index = this.expanded.indexOf(value)
            return !!~index
        }
    }
}